.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1vw;

    margin-top: 1.5vw;

    @media (max-width: 900px) {
        margin-top: 2vw;
        margin-left: 6vw;
    }

    .typeBtn,
    .clickedTypeBtn {
        padding: 0.5vw 1.2vw;
        border-radius: 2vw;
        font-family: "HakgyoansimBareonbatangB";
        font-size: 1.1vw;

        cursor: pointer;

        @media (max-width: 900px) {
            padding: 1vw 3vw;
            border-radius: 3vw;
            font-size: 3vw;
        }
    }

    .typeBtn {
        background-color: #edcfb1;
    }

    .clickedTypeBtn {
        background-color: #FFFFFF;
        border: 2px solid #d1af73
    }
}

.imgContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;

    margin-left: 1vw;
    width: 100%;

    @media (max-width: 900px) {
        margin: 5vw 0 0 0;
    }

    .btn{
        width: 300vw;
    }

    .typeImg {
        margin: 1vw 1vw 0 1vw;
        padding: 1vw 2vw;
        width: 35vw;
        background-color: #FFFFFF;
        border: 5px solid #d1af73;

        /* 기본 애니메이션이 없을 때 */
        opacity: 1;
        transition: opacity 0.5s ease-out;

        @media (max-width: 900px) {
            width: 70%;
        }
    }

    /* 애니메이션 클래스 */
    .fadeIn {
        opacity: 0;
        animation: fadeIn 1.3s ease-out forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.98;
    }

    100% {
        opacity: 1;
    }
}   