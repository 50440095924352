.imageContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    display: block;

    .mainImage {
        width: 100%;
        height: auto;
        display: block;
        transform: translateY(-100%); /* 처음에는 화면 밖 위쪽에 위치 */
        opacity: 0; /* 처음에는 보이지 않음 */
        animation: slideInFromTop 1.5s ease-out forwards; /* 위에서 아래로 올라오는 애니메이션 */
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* 오버레이 투명도 */
        pointer-events: none;
        opacity: 0;
        animation: fadeIn 1s ease-out 0.5s forwards; /* 0.5초 지연 후 페이드 인 애니메이션 */
    }

    /* 이미지가 위에서 아래로 슬라이드되는 애니메이션 */
    @keyframes slideInFromTop {
        0% {
            transform: translateY(-100%); /* 처음에는 화면 밖 위쪽 */
            opacity: 0; /* 처음에는 보이지 않음 */
        }
        70% {
            transform: translateY(20px); /* 화면에 거의 들어왔을 때 */
            opacity: 0.7; /* 약간 보이도록 */
        }
        100% {
            transform: translateY(0); /* 최종적으로 원래 위치로 */
            opacity: 1; /* 완전히 보이게 */
        }
    }

    /* 오버레이 페이드 인 애니메이션 */
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }




    .mainImageTextBox {
        position: absolute;
        top: 25vw;
        left: 0;
        transform: translate(5%, -50%);
        color: #ffe2b0;
        display: block;

        .mainImageTextSub {
            font-family: 'HakgyoansimBareonbatangB';
            padding: 0 1vw 0 1.2vw;
            font-weight: 400;
            font-size: 1.2vw;
        }

        .mainImageTitleBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 1vw;

            .mainImageText {
                font-family: 'HakgyoansimBareonbatangB';
                margin: 0 2vw 0 0;
                font-weight: 700;
                font-size: 2vw;
            }

            .mainImageText:first-of-type {
                padding: 0 3vw 0 1vw;
                text-align: center;
                font-family: 'HakgyoansimBareonbatangB';
                font-weight: 700;
                margin: 0 2vw 0 0;
                font-size: 2vw;
                line-height: 1vw;
            }

            .mainImageLine {
                margin: 0 2vw 0 0;
                width: 15vw;
                border: 0.5px solid #ffe2b0;
            }
        }

        // 텍스트 애니메이션 적용
        .mainImageTitleBox div {
            display: inline-block;
            opacity: 0;
            transform: translateY(20px);
            animation: fadeUp 1s ease forwards;
        }

        .mainImageTitleBox div:nth-child(1) {
            animation-delay: 0.3s;
        }

        .mainImageTitleBox div:nth-child(2) {
            animation-delay: 0.6s;
        }

        .mainImageTitleBox div:nth-child(3) {
            animation-delay: 0.9s;
        }
    }
}

// Fade up 애니메이션 정의
@keyframes fadeUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.section1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #fffbf5;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 7vw;
        padding-top: 9vw;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        font-size: 3.5vw;

        .text1 {
            font-size: 2.5vw;
            color: #c98f25;
        }

        .text2 {
            margin-top: 3vw;
            font-size: 1.5vw;
            color: #461900;
        }

        .text3 {
            margin-top: 4vw;
            font-size: 1vw;
            line-height: 2vw;
            color: #a2978a;
        }

        .text4 {
            margin-top: 5vw;
            margin-left: 2vw;
            font-size: 1.2vw;
            color: #241400;

            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .menuBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50vw;
        height: 100%;
        background-color: #FFFFFF;

        img {
            margin-top: 5vw;
        }

        .btn {
            margin-top: 3vw;
            padding: 1vw 1.5vw;
            background-color: #6e5e55;
            border-radius: 10vw;
            color: #FFFFFF;
            font-size: 1.2vw;
            text-decoration: none;
        }
    }
}

.section2 {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #fff6ea 85%, #c4825f 15%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .textBox {
        margin-top: 14vw;
        margin-left: 5.5vw;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        font-size: 3.5vw;

        .text1,
        .text2,
        .text3,
        .text4,
        .text5 {
            opacity: 0;
            animation: fadeUp 1.8s ease forwards;
        }

        .text1 {
            font-size: 1.5vw;
            animation-delay: 0.3s;
            color: #c98f25;
        }

        .text2 {
            margin-top: 1.0vw;
            line-height: 3.5vw;
            font-size: 2vw;
            animation-delay: 0.6s;
            color: #461900;
        }

        .text3 {
            margin-top: 3.8vw;
            font-size: 1vw;
            animation-delay: 0.9s;
            color: #a2978a;
        }

        .text4 {
            margin-top: 1.5vw;
            line-height: 2vw;
            font-size: 1vw;
            animation-delay: 1.2s;
            color: #381400;
        }

        .text5 {
            margin-top: 1.5vw;
            line-height: 1.8vw;
            font-size: 1vw;
            animation-delay: 1.5s;
            color: #5e5e5e;
        }

        span {
            font-weight: 900;
        }
    }

    img {
        margin-top: 4.8vw;
        margin-right: 5vw;
        width: 30vw;
    }
}

.section3 {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1vw;

        width: 25%;
        height: 100vh;
        background-color: #fff6ea;

        img {
            width: 7vw;
        }
    }

    .boxTitle {
        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        margin-top: 2vw;
        font-size: 2vw;
        color: #b88c52;
    }

    .boxText1 {
        text-align: center;
        margin-top: 2vw;
        font-size: 1.2vw;
        line-height: 1.5vw;
    }

    .boxText2 {
        text-align: center;
        margin-top: 2vw;
        font-size: 1vw;
        line-height: 1.5vw;
        color: #968978;
    }

    .boxText3 {
        text-decoration: none;
        font-family: 'HakgyoansimBareonbatangB';
        text-align: center;
        margin-top: 2.5vw;
        font-size: 1.2vw;
        color: #fff6ea;
        text-decoration-color: #fff6ea;
    }

    .box:hover {
        background-color: #095052;
        color: #fff7ea;
    }
}

.section4 {
    display: flex;
    flex-direction: row;

    width: 100vw;
    height: 100%;

    background-color: #fff6ea;

    .imageBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 40vw;
            height: 100vh;
        }

        .text1,
        .text2,
        .text3 {
            width: 40vw;
            position: absolute;
            color: #FFFFFF;
            text-align: center;
        }

        .text1 {
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #FFFFFF;
            font-size: 1.5vw;
            font-family: "HakgyoansimBareonbatangB";
        }

        .text2 {
            top: 53%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #d1af73;
            font-size: 2.5vw;
            font-family: "GmarketSansMedium";
        }

        .text3 {
            top: 62%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #FFFFFF;
            font-size: 1.2vw;
            font-family: "GmarketSansMedium";
        }
    }

    .textBox {
        margin-top: 8vw;
        margin-left: 4vw;

        width: 45vw;

        .text1 {
            font-family: "HakgyoansimBareonbatangB";
            font-size: 2.2vw;
            color: #c98f25;
        }

        .text2 {
            position: relative;
            top: 2vw;
            left: 90%;
            width: 100%;
            text-align: center;
            text-decoration: none;
            font-family: "HakgyoansimBareonbatangB";
            font-size: 1.5vw;
            color: #d1af73;
        }
    }
}

.section5 {
    width: 100%;
    height: 100%;
}

//모바일 메인 스타일

.mobileMain {

    .imageContainer {
        position: relative; // Make this relative to allow absolute positioning of overlay
        width: 100%;
        height: 100%;

        .mainImage {
            width: 100vw;
            height: auto;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%; // Match the container's size
            background-color: rgba(0, 0, 0, 0.5); // 50% opacity
            pointer-events: none;
        }

        .mainImageTextBox {

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            position: absolute;
            top: 50%;
            left: 3%;

            transform: translate(5%, -50%);
            color: #ffe2b0;

            .mainImageTitle {
                font-family: 'HakgyoansimBareonbatangB';
                font-weight: 700;
                font-size: 5vw;
            }

            .mainImageTextSub {
                margin-top: 1.3vw;
                font-family: 'HakgyoansimBareonbatangA';
                font-weight: 200;
                font-size: 3vw;
            }

            .mainImageLine {
                margin: 2vw 0;
                height: 10vw;
                border-left: 2px solid #ffe2b0;
            }

            .mainImageText {
                font-family: 'HakgyoansimBareonbatangB';
                font-weight: 700;
                font-size: 4.5vw;
            }
        }

        .mainImageTextBox div {
            display: inline-block;
            opacity: 0;
            transform: translateY(20px);
            animation: fadeUp 1.5s ease forwards;
        }

        .mainImageTextBox div:nth-child(1) {
            animation-delay: 0.3s;
        }

        .mainImageTextBox div:nth-child(2) {
            animation-delay: 0.6s;
        }

        .mainImageTextBox div:nth-child(3) {
            animation-delay: 0.9s;
        }

        .mainImageTextBox div:nth-child(4) {
            animation-delay: 1.2s;
        }

        @keyframes fadeUp {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .container1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        padding: 10vw 0 0 7vw;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;

        background-color: #fffbf5;

        .text1 {
            font-size: 8vw;
            color: #c98f25;
        }

        .text2 {
            margin-top: 8vw;
            font-size: 4vw;
            color: #461900;
        }

        .text3 {
            margin-top: 8vw;
            font-size: 2.5vw;
            font-family: 'HakgyoansimBareonbatangA';
            font-weight: 300;
            line-height: 4vw;
            color: #928677;
        }

        .text4 {
            margin: 15vw 0 17vw 3vw;
            font-size: 3vw;
            color: #241400;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

    }

    .container2 {
        width: 100%;
        height: 100%;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5vw;

            margin: 30vw 0;


            .btn {
                margin-top: 3vw;
                padding: 2.2vw 3.5vw;
                background-color: #6e5e55;
                border-radius: 10vw;
                color: #FFFFFF;
                font-size: 3.2vw;
                text-decoration: none;
            }
        }
    }

    .container3 {
        position: relative;
        padding-top: 12vw;
        padding-left: 5.5vw;

        height: 80vw;
        background: linear-gradient(to bottom, #fff6ea 85%, #c4825f 15%);

        .textbox {
            font-family: 'HakgyoansimBareonbatangB';
            font-weight: 700;
            font-size: 3.5vw;

            .text1,
            .text2,
            .text3,
            .text4,
            .text5 {
                opacity: 0;
                animation: fadeUp 1.8s ease forwards;
            }

            .text1 {
                font-size: 4vw;
                animation-delay: 0.3s;
                color: #c98f25;
            }

            .text2 {
                margin-top: 2vw;
                font-size: 5vw;
                line-height: 5.5vw;
                animation-delay: 0.6s;
                color: #461900;
            }

            .text3 {
                margin-top: 5.8vw;
                font-size: 3.5vw;
                animation-delay: 0.9s;
                color: #a2978a;
            }

            .text4 {
                margin-top: 2.5vw;
                font-size: 3vw;
                line-height: 3.5vw;
                animation-delay: 1.2s;
                color: #381400;
            }

            .text5 {
                margin: 3vw 0;
                font-size: 2.2vw;
                line-height: 3.2vw;
                animation-delay: 1.5s;
                color: #5e5e5e;
            }

            span {
                font-weight: 900;
            }
        }

        img {
            position: absolute;
            bottom: 3vw;
            right: 6vw;

            width: 30vw;
        }
    }
}

.container4 {
    width: 100%;
    height: 100%;
    background-color: #fff6ea;

    .text1 {
        padding: 8vw 0 0 6vw;
        font-family: "HakgyoansimBareonbatangB";
        font-size: 5vw;
        color: #c98f25;
    }

    .text2 {
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-family: "HakgyoansimBareonbatangB";
        font-size: 1.5vw;
        color: #d1af73;

        div {
            @media (max-width: 900px) {
                padding: 6vw 4vw 7vw 0;
                text-align: right;
                font-size: 4vw;
            }
        }
    }
}

.container5 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 100vw;
        height: 90vw;
    }

    .text1,
    .text2,
    .text3 {
        width: 100%;
        position: absolute;
        color: #FFFFFF;
        text-align: center;
    }

    .text1 {
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        font-size: 3vw;
        font-family: "HakgyoansimBareonbatangB";
    }

    .text2 {
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #d1af73;
        font-size: 5vw;
        font-family: "GmarketSansMedium";
    }

    .text3 {
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        font-size: 3.5vw;
        font-family: "GmarketSansMedium";
    }
}

.container7 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    background-color: #fff8ee;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin: 4vw 0 0 2.5vw;
        width: 100%;

        .title {
            color: #095052;
            font-family: 'HakgyoansimBareonbatangB';
            font-size: 4vw;
            font-weight: 400;
            line-height: 6vw;

            span:first-of-type {
                font-family: 'HakgyoansimBareonbatangB';
                color: #b88c52;
                font-weight: 800;
                font-size: 5vw;
            }
        }

        .subTitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1vw;
            margin-top: 2vw;
            width: 100%;

            .textLine {
                border: 3px solid #095052;
                width: 0;
                height: 7vw;
            }

            .subText {
                font-family: 'HakgyoansimBareonbatangB';
                color: #0f0f1d;
                margin: 0 0 0 0;
                font-size: 3vw;
                line-height: 4vw;
            }

        }
    }

    img {
        margin: 2vw 0 0 5vw;
        width: 90vw;

    }
}
.section8 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100vw;
    height: 100vh;

    background-color: #fffbf5;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin: 12vw 0 0 2.5vw;
        width: 80%;

        .title {
            color: #095052;
            font-family: 'HakgyoansimBareonbatangB';
            font-size: 2vw;
            font-weight: 600;
            line-height: 3.5vw;

            span:first-of-type {
                color: #b88c52;
                font-weight: 800;
                font-size: 2.5vw;
            }
        }

        .subTitle {
            
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1vw;
            margin-top: 2vw;
            width: 100%;

            .textLine {
                border: 4px solid #095052;
                width: 0;
                height: 3.5vw;
            }

            .subText {
                font-family: 'HakgyoansimBareonbatangB';
                font-size: 1.3vw;
                line-height: 2vw;
            }

        }
    }

    img {
        margin: 8vw 5VW 2vw 0;
        width: 40vw;

    }
}

.section9 {
    margin-top: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center; /* 이미지를 가로 방향으로 중앙 배치 */
    align-items: flex-start; /* 이미지의 세로 정렬 (flex-start는 상단 정렬) */
    width: 100vw;
    height: auto;
    background-color: #fff6ea;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin: 5vw 0 0 1vw;
        width: 40%;
        height: auto;

        .title {
            color: #095052;
            font-family: 'HakgyoansimBareonbatangB';
            font-size: 2vw;
            font-weight: 600;
            line-height: 2.5vw;
            margin: 2vw 0 0 0;

            span:first-of-type {
                color: #b88c52;
                font-weight: 800;
                font-size: 2vw;
            }
        }

        .subTitle {
            
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1vw;
            margin-top: 2vw;
            width: 100%;

            .textLine {
                border: 4px solid #095052;
                width: 0;
                height: 3.5vw;
            }

            .subText {
                font-family: 'HakgyoansimBareonbatangB';
                font-size: 1.3vw;
                line-height: 2vw;
            }

        }
    }

    img {
        margin: 2vw 0 1vw 0; /* 기본 마진 설정 */
        width: 100%; /* 이미지를 부모 요소의 가로 너비에 맞게 조정 */
        height: auto; /* 세로 비율에 맞춰 자동으로 크기 조정 */
        object-fit: cover; /* 이미지를 자르지 않고 비율을 맞추기 위해서 사용 */
    }
    
    @media (max-width: 768px) {
        img {
            margin: 1vw 0 2vw 0; /* 모바일 화면에서 여백을 다르게 설정 */
            width: 100%; /* 모바일에서도 가로 크기를 100%로 설정 */
            height: auto; /* 세로 비율에 맞춰 자동으로 크기 조정 */

        }
    }
}   