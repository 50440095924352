.container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    position: fixed;
    top: 10vw;
    left: 0vw;

    height: 28vw;

    .openPopupBtn {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 3vw;
        height: 28vw;

        background-color: #095052;
        border-top-right-radius: 1vw;
        border-bottom-right-radius: 1vw;

        .btnIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #FFFFFF;
            width: 100%;
            height: 3vw;
        }

        .btnText {
            margin-bottom: 1vw;

            font-family: 'HakgyoansimBareonbatangB';
            font-weight: 500;
            font-size: 1.3vw;
            color: #FFFFFF;
            writing-mode: vertical-rl;
            text-orientation: upright;
            text-align: center;
        }
    }

    z-index: 1000;
    cursor: pointer;
}

.imgContainer {
    display: flex;
    flex-direction: row;
    background-color: #00000076;
    height: 100%;

    .popupImg {
        height: 100%;
        width: auto;
        border-right: 1px solid #FFFFFF; /* 각 이미지 사이에 구분선 추가 */
    }

    .popupImg:last-child {
        border-right: none; /* 마지막 이미지에는 구분선 제거 */
    }
}
